import React, { Component } from 'react'
import { CrumbsBlackSunUnenclosed } from "../../../../components/Bread_Crumbs";
import imgs from '../../../../components/cms/images_repo.json';
import {
    SidebarBSGallery,
    SidebarBSMap,
    SidebarBSTimeline,
    SidebarBSWiki
} from "../../../../components/Sidebar"

function Unenclosed_cydonian_girl_covers() {
    return (
        <>
            <PageClass />
        </>
    );
}

class PageClass extends Component {

    state = {
        quote: {},
    }

    componentDidMount() {
        document.title = "Кидонианка, прототипы обложки"
    }

    render() {
        return (
            <div>
                <CrumbsBlackSunUnenclosed name={<span><i className="fa-solid fa-triangle-exclamation"></i> Кидонианка: прототипы обложки</span>} />
                <div key="mainRow" className="row">
                    <div className="col-lg-8">
                        <div className="row"><h2 className="title-bg">Кидонианка: прототипы обложки</h2></div>
                        <div className='row'>
                            <div className="alert alert-danger">
                                <p className="card-text text-justify">Осторожно: вырвиглазность!</p>
                                <p className="card-text text-justify">Заказал я как-то у одной барышни обложку для
                                    книги... и вот такие образы для Розали она предложила. До сих пор вижу эти "арты" в
                                    кошмарах. Про тот факт, что картинки - отфильтрованный в фотошопе плагиат Mass
                                    Effect, а никакой не рисунок, и говорить не стоит.</p>
                            </div>
                            <div className={"col-lg-6"}>
                                <a data-fancybox href={imgs["bs_cydonian_girl_weird_cover_1"].link}>
                                    <img className="img-max-full-width img-thumbnail-v2" alt=""
                                        src={imgs["bs_cydonian_girl_weird_cover_1"].link} />
                                </a>
                            </div>
                            <div className={"col-lg-6"}>
                                <a data-fancybox href={imgs["bs_cydonian_girl_weird_cover_2"].link}>
                                    <img className="img-max-full-width img-thumbnail-v2" alt=""
                                        src={imgs["bs_cydonian_girl_weird_cover_2"].link} />
                                </a>
                            </div>
                        </div>
                        <div className='row'>
                            <div className={"col-lg-6"}>
                                <a data-fancybox href={imgs["bs_cydonian_girl_weird_cover_3"].link}>
                                    <img className="img-max-full-width img-thumbnail-v2" alt=""
                                        src={imgs["bs_cydonian_girl_weird_cover_3"].link} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="well">
                            <SidebarBSWiki />
                            <hr />
                            <SidebarBSMap />
                            <hr />
                            <SidebarBSTimeline />
                            <hr />
                            <SidebarBSGallery />
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export { Unenclosed_cydonian_girl_covers };
